var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-goods-container"
  }, [_vm.goods.review_finished_datetime ? _c('div', {
    staticClass: "review-goods-container__finished-logo"
  }, [_c('van-image', {
    attrs: {
      "width": "1.78667rem",
      "height": "1.6rem",
      "src": require('@/assets/images/yifuhe.png')
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "review-goods-container__content"
  }, [_c('div', {
    staticClass: "review-goods-container__content-img",
    on: {
      "click": function click($event) {
        return _vm.$emit('image-preview', _vm.goods.image);
      }
    }
  }, [_c('van-image', {
    attrs: {
      "width": "2.26666rem",
      "height": "2.26666rem",
      "src": _vm.goods.image
    }
  })], 1), _c('div', {
    staticClass: "review-goods-container__content-text"
  }, [_c('div', {
    staticClass: "review-goods-container__content-name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "review-goods-container__size-storage"
  }, [_c('span', [_vm._v("规格：1 * " + _vm._s(_vm.goods.size))])]), _c('div', {
    staticClass: "review-goods-container__large-least"
  }, [_vm._v(" 数量： "), _vm.goods.large_unit && _vm.goods.large_volume > 0 ? _c('span', [_vm._v(_vm._s(_vm.goods.large_volume) + _vm._s(_vm.goods.large_unit))]) : _vm._e(), _vm.goods.large_volume > 0 && _vm.goods.least_volume > 0 ? _c('span', {
    staticStyle: {
      "padding": "0 2px"
    }
  }, [_vm._v("+")]) : _vm._e(), _vm.goods.least_volume > 0 ? _c('span', [_vm._v(_vm._s(_vm.goods.least_volume) + _vm._s(_vm.goods.least_unit))]) : _vm._e()])])]), _c('van-row', {
    staticClass: "review-goods-container__pick-info",
    attrs: {
      "type": "flex",
      "align": "center"
    }
  }, [_c('van-col', {
    attrs: {
      "span": 14
    }
  }, [_c('div', [_c('span', [_vm._v("已拣货：")]), _vm.goods.large_unit && _vm.goods.pick_large_volume > 0 ? _c('span', [_vm._v(_vm._s(_vm.goods.pick_large_volume) + _vm._s(_vm.goods.large_unit))]) : _vm._e(), _vm.goods.pick_large_volume > 0 && _vm.goods.pick_least_volume > 0 ? _c('span', {
    staticStyle: {
      "padding": "0 2px"
    }
  }, [_vm._v("+")]) : _vm._e(), _vm.goods.pick_least_volume > 0 ? _c('span', [_vm._v(_vm._s(_vm.goods.pick_least_volume) + _vm._s(_vm.goods.least_unit))]) : _vm._e()]), _vm.outLargeVolume > 0 || _vm.outLeastVolume > 0 ? _c('div', [_c('span', [_vm._v("缺"), _c('i', {
    staticStyle: {
      "padding": "0 7px"
    }
  }), _vm._v("货：")]), _vm.goods.large_unit && _vm.outLargeVolume > 0 ? _c('span', [_vm._v(_vm._s(_vm.outLargeVolume) + _vm._s(_vm.goods.large_unit))]) : _vm._e(), _vm.outLargeVolume > 0 && _vm.outLeastVolume > 0 ? _c('span', {
    staticStyle: {
      "padding": "0 2px"
    }
  }, [_vm._v("+")]) : _vm._e(), _vm.outLeastVolume > 0 ? _c('span', [_vm._v(_vm._s(_vm.outLeastVolume) + _vm._s(_vm.goods.least_unit))]) : _vm._e()]) : _vm._e()]), _c('van-col', {
    staticClass: "review-goods-container__pick-button",
    attrs: {
      "span": 10
    }
  }, [_c('van-button', {
    attrs: {
      "color": "#01ADFF",
      "round": ""
    },
    on: {
      "click": _vm.handleReview
    }
  }, [_vm._v("确认复核")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }