var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "review-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "复核明细",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _vm.noticeText ? _c('van-sticky', {
    staticClass: "notice",
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-notice-bar', {
    attrs: {
      "left-icon": "volume-o",
      "text": _vm.noticeText
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "review-detail-container__goods"
  }, _vm._l(_vm.goodses, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods-container"
    }, [_c('van-cell', {
      attrs: {
        "title": goods.line_name,
        "value": goods.client_name
      }
    }), _c('review-goods', {
      attrs: {
        "goods": goods
      },
      on: {
        "review-goods": _vm.reviewGoods,
        "image-preview": _vm.handleImagePreview
      }
    })], 1);
  }), 0), _vm.goodses.length > 0 ? _c('div', {
    staticClass: "fixed-bottom-btn review-detail-container__footer"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.notReviewCount > 0,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.finished
    }
  }, [_vm.notReviewCount === 0 ? _c('span', [_vm._v("复核完成")]) : _c('span', [_vm._v("还有" + _vm._s(_vm.notReviewCount) + "种商品未复核")])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }