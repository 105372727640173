<template>
  <div class="review-goods-container">
    <div v-if="goods.review_finished_datetime" class="review-goods-container__finished-logo">
      <van-image width="1.78667rem" height="1.6rem" :src="require('@/assets/images/yifuhe.png')" />
    </div>
    <div class="review-goods-container__content">
      <div class="review-goods-container__content-img" @click="$emit('image-preview', goods.image)">
        <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
      </div>
      <div class="review-goods-container__content-text">
        <div class="review-goods-container__content-name">{{ goods.name }}</div>
        <div class="review-goods-container__size-storage">
          <span>规格：1 * {{ goods.size }}</span>
        </div>
        <div class="review-goods-container__large-least">
          数量：
          <span v-if="goods.large_unit && goods.large_volume > 0">{{ goods.large_volume }}{{ goods.large_unit }}</span>
          <span v-if="goods.large_volume > 0 && goods.least_volume > 0" style="padding: 0 2px">+</span>
          <span v-if="goods.least_volume > 0">{{ goods.least_volume }}{{ goods.least_unit }}</span>
        </div>
      </div>
    </div>
    <van-row type="flex" align="center" class="review-goods-container__pick-info">
      <van-col :span="14">
        <div>
          <span>已拣货：</span>
          <span v-if="goods.large_unit && goods.pick_large_volume > 0">{{ goods.pick_large_volume }}{{ goods.large_unit }}</span>
          <span v-if="goods.pick_large_volume > 0 && goods.pick_least_volume > 0" style="padding: 0 2px">+</span>
          <span v-if="goods.pick_least_volume > 0">{{ goods.pick_least_volume }}{{ goods.least_unit }}</span>
        </div>
        <div v-if="outLargeVolume > 0 || outLeastVolume > 0">
          <span>缺<i style="padding: 0 7px" />货：</span>
          <span v-if="goods.large_unit && outLargeVolume > 0">{{ outLargeVolume }}{{ goods.large_unit }}</span>
          <span v-if="outLargeVolume > 0 && outLeastVolume > 0" style="padding: 0 2px">+</span>
          <span v-if="outLeastVolume > 0">{{ outLeastVolume }}{{ goods.least_unit }}</span>
        </div>
      </van-col>
      <van-col class="review-goods-container__pick-button" :span="10">
        <van-button color="#01ADFF" round @click="handleReview">确认复核</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { unitsToVolume, volumeToUnits } from '@/utils'
export default {
  name: 'ReviewGoods',
  props: {
    goods: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      outLargeVolume: 0,
      outLeastVolume: 0
    }
  },
  watch: {
    goods: {
      handler: function() {
        this.calcOutVolume()
      },
      deep: true
    }
  },
  created() {
    this.calcOutVolume()
  },
  methods: {
    handleReview() {
      this.$emit('review-goods', this.goods)
    },
    calcOutVolume() {
      const pickVolume = unitsToVolume(this.goods.pick_large_volume, this.goods.pick_least_volume, this.goods.size)
      const outVolume = this.goods.volume - pickVolume
      const units = volumeToUnits(outVolume, this.goods.size)
      this.outLargeVolume = units.largeVolume
      this.outLeastVolume = units.leastVolume
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/styles/ellipsis";
  @import "src/styles/word-break";
  @import "src/styles/color";
  .review-goods-container {
    position: relative;
    /*margin-bottom: 10px;*/
    background-color: #fff;
    /*border-radius: 6px;*/
    /*box-shadow: 0 0 6px 0 rgba(48, 48, 48, 0.07);*/
    &__content {
      display: flex;
      padding: 15px 15px 5px 15px;
    }
    &__large-least {
      color: $amountRedColor;
    }
    &__content-text {
      @extend %ellipsis;
      padding: 0 10px;
    }
    &__content-name {
      @extend %word-break;
      color: #1C1C1C;
      // margin-bottom: 5px;
    }
    &__size-storage {
      color: #646566;
      // margin-bottom: 5px;
    }
    &__pick-info {
      padding: 0 15px 15px 15px;
    }
    &__pick-button {
      text-align: right;
      .van-button {
        height: 30px;
        /*width: 85px;*/
      }
    }
    &__finished-logo {
      position: absolute;
      top: 0;
      right: 0;
      /*width: 80px;*/
      /*height: 80px;*/
      /*z-index: 10;*/
    }
  }
</style>
