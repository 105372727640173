<template>
  <div class="review-detail-container">
    <my-nav-bar
      title="复核明细"
      left-text="返回"
      left-arrow
    />
    <van-sticky v-if="noticeText" class="notice" offset-top="1.22667rem">
      <van-notice-bar left-icon="volume-o" :text="noticeText" />
    </van-sticky>
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div class="review-detail-container__goods">
        <div v-for="(goods, index) in goodses" :key="index" class="goods-container">
          <van-cell :title="goods.line_name" :value="goods.client_name" />
          <review-goods
            :goods="goods"
            @review-goods="reviewGoods"
            @image-preview="handleImagePreview"
          />
        </div>
      </div>
      <div v-if="goodses.length > 0" class="fixed-bottom-btn review-detail-container__footer">
        <van-button :disabled="notReviewCount > 0" square block @click="finished">
          <span v-if="notReviewCount === 0">复核完成</span>
          <span v-else>还有{{ notReviewCount }}种商品未复核</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewGoods from './components/goods'
import reviewApi, { getNotice } from '@/api/review'
import { now, time } from '@/utils'
import MyNavBar from '@/components/my-nav-bar'
import { ImagePreview } from 'vant'
export default {
  name: 'ReviewDetail',
  components: { ReviewGoods, MyNavBar },
  data() {
    return {
      goodses: [],
      showEmpty: false,
      noticeText: ''
    }
  },
  computed: {
    notReviewCount() {
      let notReviewCount = this.goodses.length
      this.goodses.map(goods => {
        if (goods.review_finished_datetime) { notReviewCount-- }
      })
      return notReviewCount
    }
  },
  created() {
    this.getDetail()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getDetail() {
      this.beginLoad()
      reviewApi.detail().then(res => {
        this.endLoad()
        this.goodses = res.data
        this.showEmpty = this.goodses.length === 0

        this.handleGetNotice()
        this.timer = setInterval(() => {
          this.handleGetNotice()
        }, 10000)
      })
    },
    handleGetNotice() {
      getNotice().then(res => {
        this.noticeText = res.data
      })
    },
    reviewGoods(goods) {
      const data = {
        order_id: goods.order_id,
        goods_id: goods.goods_id
      }
      this.beginLoad()
      reviewApi.goods(data).then(res => {
        this.success(res.msg)
        const needSort = !goods.review_finished_datetime
        goods.review_finished_datetime = now()
        this.endLoad()
        if (needSort) {
          this.goodses.sort(function(a, b) {
            if (!a.review_finished_datetime && b.review_finished_datetime) {
              return -1
            } else if (!a.review_finished_datetime && !b.review_finished_datetime) {
              if (time(a.pick_finished_datetime) > time(b.pick_finished_datetime)) { return -1 } else { return 1 }
            } else {
              return 1
            }
          })
        }
      })
    },
    finished() {
      // let data = {
      //   order_id: this.$route.query.order_id
      // };
      this.beginLoad()
      reviewApi.finish().then(res => {
        this.success(res.msg)
        this.$router.go(-1)
      })
    },
    handleImagePreview(image) {
      console.log(image)
      ImagePreview([image])
    }
  }
}
</script>

<style lang="scss" scoped>
  .review-detail-container {
    &__goods {
      padding: 15px 15px 55px 15px;
    }
    &__footer {
      background-color: #fff;
      z-index: 99;
      .van-button {
        height: 50px;
      }
    }
  }
  .goods-container {
    margin-bottom: 10px;
    .van-cell__value {
      flex: 3;
    }
  }
</style>
