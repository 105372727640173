import request from '@/utils/request'

export default {

  order(params) {
    return request({
      method: 'get',
      url: 'review',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'review/detail',
      params
    })
  },
  goods(data) {
    return request({
      method: 'put',
      url: 'review/goods',
      data
    })
  },
  finish(data) {
    return request({
      method: 'put',
      url: 'review/finish',
      data
    })
  }

}

export function getNotice() {
  return request({
    method: 'get',
    url: 'review/notice'
  })
}
